import * as React from 'react'
import { graphql } from 'gatsby'
import KnowledgeBaseLayout, { LeftBarItem } from '../layouts/KnowledgeBaseLayout'

// @ts-ignore
import leftMenu from './leftMenu.json';

const createLeftItemsFromSection = (section: any) => {

  const parseItems = (items: any, base: string, level: number, acc: LeftBarItem[]) => {
    items.forEach((item: any) => {
      const thisUrl = item.url ? `${base}/${item.url}` : ''
      const thisItem : LeftBarItem = {
        title: item.name,
        level,
        url: thisUrl
      }
      acc.push(thisItem)

      if (item.items) {
        parseItems(item.items, thisUrl.length != 0 ? thisUrl : base, level + 1, acc)
      }
    })
    return acc
  }
  return parseItems(section.items, section.url, 0, []);
}

// @ts-ignore
const KnowledgeBasePage : React.FunctionComponent = (ctx: any) => {
  const data = ctx.data
  const html = data.content.html
  const leftMenuData = leftMenu[ctx.pageContext.section]

  //const leftBarItems = (!data.section || data.section.sections.length == 0) ? [] : createLeftItemsFromSection(data.section.sections[0])
  //const leftBarItems: LeftBarItem[] = []
  const leftBarItems = createLeftItemsFromSection(leftMenuData)

  return (
    <KnowledgeBaseLayout currentUrl={ctx.path} leftBarItems={leftBarItems}>
      <main className="markdown" dangerouslySetInnerHTML={{__html: html}} />
    </KnowledgeBaseLayout>
  )
}

export const query = graphql`
  query ($url: String!) {
    content: markdownRemark(fields: {slug: {eq: $url}}) {
      html
    }
  }`

export default KnowledgeBasePage
